.section {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  padding: 40px;
}

.section.half-height {
  min-height: 50vh;
}

.section-inner {
  width: 100%;
  height: 100%;
}

.section-content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
}

.section-paragraphs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.section-paragraph {
  flex: 1;
  margin: 10px;
  min-width: 200px;
  max-width: 300px;
}

.section-button {
  margin-top: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #539E59;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.button:hover {
  background-color: #3F8A45;
}


/* 與我聯繫的 CSS 規則 */
.special-section .section-content {
  background-color: transparent; /* 設置背景顏色為透明 */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-left {
  flex: 0 0 40%;
  margin-right: 20px;
  max-width: 40%;
}

.section-right {
  flex: 1;
}

.right-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: darkslategrey;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 20px;
  max-height: fit-content;
}

.left-content {
  padding: 20px;
}

.left-content form {
  display: flex;
  flex-direction: column;
}

.left-content label {
  margin-bottom: 10px;
  font-weight: bold;
}

.left-content input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.left-content button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.left-content button:hover {
  background-color: #0056b3;
}