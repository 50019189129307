.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* 讓導航列寬度為100% */
  z-index: 1000; /* 確保導航列在其他內容上方 */
  box-sizing: border-box; /* 確保內距和邊距不會影響總寬度 */
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo .logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 10px; /* 添加間距以避免按鈕重疊 */
}

.navbar-links .line-customer-service {
  text-decoration: none;
  color: #539E59;
  padding: 8px 16px;
  border: 2px solid #539E59;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links .line-customer-service:hover {
  background-color: #539E59;
  color: #fff;
}

.navbar-links .apply-button {
  text-decoration: none;
  color: #fff;
  background-color: #539E59;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navbar-links .apply-button:hover {
  background-color: #3d6f45;
}
