.online-application {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .online-application h2 {
    text-align: center;
  }
  
  .online-application form {
    display: flex;
    flex-direction: column;
  }
  
  .online-application label {
    margin-bottom: 10px;
  }
  
  .online-application input[type="text"],
  .online-application input[type="email"],
  .online-application input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  
  .online-application button {
    margin-top: 20px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .online-application button:hover {
    background-color: #0056b3;
  }
  