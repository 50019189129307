body, html {
  margin: 0;
  padding: 0;
  padding-top: 40px; /* 根據導航列的高度調整這個值 */
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden; /* 防止水平滾動條 */
}

section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
}
